import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_user_info'
import get_menu_data from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_menu_data/1.0.0'
import aRouter from '@/router'

const get_first_left_child_entity_by_tree_entity = function (pParameter) {
    if (!pParameter) pParameter = {};
    const tree_entity = pParameter.tree_entity;
    const property_name_sub_entity_list = pParameter.property_name_sub_entity_list;

    if (!tree_entity) {
        const msg = `get_first_left_child_entity_by_tree_entity parameter error, code=001`;
        console.error(msg);
        console.trace();
        return;
    }

    const sub_entity_list = tree_entity[property_name_sub_entity_list];
    if (!_.isArray(sub_entity_list)) {
        return tree_entity;
    }

    if (sub_entity_list.length <= 0) {
        return tree_entity;
    }

    const next_tree_entity = sub_entity_list[0];
    return get_first_left_child_entity_by_tree_entity({
        tree_entity: next_tree_entity,
        property_name_sub_entity_list: property_name_sub_entity_list,
    });
};

const get_first_left_child_entity_by_tree_entity_list = function (pParameter) {
    const defaultParameter = {};
    defaultParameter.tree_entity_list = [];
    defaultParameter.property_name_sub_entity_list = `sub_entity_list`;

    const parameter = extend({}, defaultParameter, pParameter);

    const tree_entity_list = parameter.tree_entity_list;
    const property_name_sub_entity_list = parameter.property_name_sub_entity_list;

    if (!_.isArray(tree_entity_list)) {
        return;
    }

    if (tree_entity_list.length <= 0) {
        return;
    }

    const tree_entity = tree_entity_list[0];
    return get_first_left_child_entity_by_tree_entity({
        tree_entity,
        property_name_sub_entity_list,
    });
};

const redirect_to_user_back_home = function (pParameter) {
    if (!pParameter) pParameter = {};
    const clear_cache_get_user_info = pParameter.clear_cache_get_user_info;

    return Q.when()
        .then(function () {
            const list = [];
            list.push(get_user_info({
                clear_cache: clear_cache_get_user_info,
            }));
            list.push(get_menu_data({
                clear_cache: clear_cache_get_user_info,
            }));
            return Q.all(list);
        })
        .spread(function (data_get_user_info, data_get_menu_data) {

            //const menu_list = data_get_menu_data.menu_list;
            let menu_list = [];
            if (data_get_menu_data.menu_list) {
                let menuListTit = ['国内机票订单', '国际机票订单', '对账管理', '余额记账', '授信记账', '个人中心', '接口对接配置', '轻松付'];
                data_get_menu_data.menu_list.forEach(value=>{
                    if (menuListTit.indexOf(value.resourceName) > -1) {
                        menu_list.push(value)
                    }
                });
            }

            const __get_first_left_child = function (first_item) {
                if (!first_item) {
                    const msg = `__get_first_left_child parameter error, code=001`;
                    console.error(msg);
                    console.trace();
                    return;
                }

                if (!first_item.sub_list) {
                    return first_item;
                }

                if (first_item.sub_list.length <= 0) {
                    return first_item;
                }

                const next_first_item = first_item.sub_list[0];
                return __get_first_left_child(next_first_item);
            };
            const get_first_left_child = function (list) {
                if (!_.isArray(list)) {
                    const msg = `get_first_left_child parameter error, code=001`;
                    console.error(msg);
                    console.trace();
                    return;
                }

                if (list.length <= 0) {
                    return;
                }

                const first_item = list[0];
                return __get_first_left_child(first_item);

            };

            // const menu = get_first_left_child(menu_list);
            const menu = get_first_left_child_entity_by_tree_entity_list({
                tree_entity_list: menu_list,
                property_name_sub_entity_list: `sub_list`,
            });

            if (!menu) {
                const msg = `redirect_to_user_back_home parameter error, code=001`;
                console.error(msg);
                console.trace();
                alert(`对不起，您没有此权限！code=001`);
                return Q.reject({msg: msg});
            }

            const route_name = menu.routeName;
            if (!route_name) {
                const msg = `redirect_to_user_back_home parameter error, code=002`;
                console.error(msg);
                console.trace();
                alert(`对不起，您没有此权限！code=002`);
                return Q.reject({msg: msg});
            }

            aRouter.push({
                name: route_name,
            });
        })
        .catch(function (ex) {
            console.error(ex);
            console.trace();
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};

export default redirect_to_user_back_home;
